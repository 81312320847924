import React from "react";
import Page from "../components/layout/page.component";
import Layout from "../components/layout/layout.component";
import Titantron from "../components/titantron/titantron.component";
import secondaryVideo from "../siteContent/secondaryPage/videos/secondaryVideo.mp4";
import { graphql } from "gatsby";
import StandardHeadline from "../components/content/elements/text/standardHeadline.component";

Impressum.propTypes = {};

function Impressum(props) {
  let impressum = props.data.allMarkdownRemark.edges.map(({ node }, index) => {
    console.log(node);
    return <div key={index} dangerouslySetInnerHTML={{ __html: node.html }} />;
  });
  return (
    <Page titlePrefix={"Impressum"}>
      <Layout background="dark" width="full">
        <Titantron title="Impressum" underline="" video={secondaryVideo} />
      </Layout>
      <Layout width="twothird" background="trans">
        <StandardHeadline type="h1" className="mb-5">
          Impressum
        </StandardHeadline>
        {impressum}
      </Layout>
    </Page>
  );
}
export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { title: { eq: "Impressum" } } }) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`;

export default Impressum;
